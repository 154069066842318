import React from 'react';
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";

const SocialLinks = () => {
    const links = [
        {
            id: 1,
            child: (
                <>
                    linkedin <FaLinkedin size={30} />
                </>
            ),
            href: 'https://www.linkedin.com/in/shake-md-hafizur-rahman-32410591/',
            style: 'rounded-tr-md'
        },
        {
            id: 2,
            child: (
                <>
                    Github <FaGithub size={30} />
                </>
            ),
            href: 'https://github.com/Hafiz0073',
        },
        {
            id: 3,
            child: (
                <>
                    Mail <HiOutlineMail size={30} />
                </>
            ),
            href: 'info@hafizhub.com',
        },
        {
            id: 4,
            child: (
                <>
                    Resume <BsFillPersonLinesFill size={30} />
                </>
            ),
            href: 'https://drive.google.com/file/d/15Ug_9E9KUrBXu-50FDzaearzmRM6O17b/view?usp=drive_link',
            download: true
        }
    ]
    return (
        <div className='flex flex-col top-[45%] left-0 fixed'>
            <ul>
                {
                    links.map(({ id, child, href, download, style }) => (
                        <li key={id}
                            className={"flex justify-between w-40 h-14 px-5 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300 bg-gray-500 " + style}
                        >
                            <a href={href}
                                target='_blank'
                                rel="noreferrer"
                                download={download}
                                className='flex justify-between items-center w-full text-white '
                            >{child}</a>

                        </li>
                    ))
                }
                {/* <li className="flex justify-between w-40 h-14 px-5 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300 bg-gray-500" + ""+ style>
                <a href="" className='flex justify-between items-center w-full text-white '>
                    <>
                        linkidin <FaLinkedin size={30} />
                    </>
                </a>
            </li> */}
            </ul>
        </div >
    );
};

export default SocialLinks;