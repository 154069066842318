import React from 'react';
import propic from '../assets/propic3.jpg'
import Typed from 'react-typed'



const Hero = () => {
    return (
        <div id="#home" className='h-full w-full bg-gradient-to-b from-black to-gray-800  text-white py-11 ' >
            <div className='max-w-screen-lg md:h-full mx-auto flex flex-col-reverse justify-center items-center md:flex-row'>
                <div className='flex flex-col justify-center px-6  items-center '>
                    <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-blue-600 sm:text-4xl sm:leading-none'> Md Hafizur<span className=' text-blue-900'> Rahman</span>
                    </h2>
                    <h2 className='text-white text-xl sm:text-3xl font-bold'> <Typed
                        strings={[
                            "I'm a Full Stack Developer",
                            "System Design and Development",
                            "Design client-side ",
                            " server-side architecture",
                            "Client Communication",
                            "Troubleshoot, debug and upgrade software",
                        ]}
                        typeSpeed={150}
                        backSpeed={100}
                        loop
                    /></h2>
                    <div className='text-gray-400 py-7'>
                        <p>Full Stack Developer with employment and voluntary experience. Also have skills and abilities to offer the business world including leadership, analytical thinking, problem solving, team working and communication. And also, passionate to learn on a graduate program and to make a contribution to the organization.</p>
                        {/* <div>
                            <button className='group text-white font-bold px-4 py-2 my-2 rounded-md flex flex-row items-center bg-gradient-to-r from-cyan-500 to-blue-400 w-fit'>
                                Contact
                                <span className='group-hover:rotate-90 duration-300'>
                                    <MdKeyboardArrowRight size={25} className='ml-2' />
                                </span>
                            </button>
                        </div> */}
                    </div>
                </div>
                <div className='pt-14'>
                    <img src={propic} alt="" className='rounded-2xl w-1/2 mx-auto md:w-full' />
                </div>

            </div>

        </div>
    );
};

export default Hero;