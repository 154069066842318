import React from 'react';

const Footer = () => {
    return (
        <footer className="footer text-center p-4 bg-black text-white">

            <p>Copyright © 2024 - All right reserved by Hafiz</p>

        </footer>
    );
};

export default Footer;