import React, { useState } from 'react';
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from 'react-scroll';


const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuLinks = [
        { id: 1, name: 'HOME', link: "#home" },
        { id: 3, name: 'PORTFOLIO', link: "#portfolio" },
        { id: 4, name: 'SKILLS', link: "#skills" },
        { id: 5, name: 'CONTACT', link: "#contact" }
    ];


    return (

        <nav className=' w-full md:h-16 h-12 py-2 mx-auto    bg-black fixed text-white md:px-10 mr-2'>

            <div className='flex justify-between items-center'>
                <div className=' '>
                    <h4 id="#home" className='text-4xl uppercase font-bold px-2 cursor-pointer  ' >
                        H<span className='text-cyan-600'>AFI</span>Z
                    </h4>
                </div>
                <ul className={`flex items-center gap-1 py-2 text-lg text-gray-900 absolute md:static  ${isMenuOpen ? 'top-12 text-black bg-white mx-auto flex flex-col w-full  items-center justify-center' : 'top-[-490px] '}`}>
                    {menuLinks?.map(({ id, name, link }) => (
                        <li
                            key={id}
                            className="px-4 cursor-pointer font-medium text-gray-500 hover:scale-105 duration-200"
                        >
                            <Link to={link} duration={500} onClick={() => setIsMenuOpen(false)}>{name}</Link>
                        </li>
                    ))}
                </ul>

                <div onClick={() => setIsMenuOpen(!isMenuOpen)} className=' right-8  cursor-pointer md:hidden w-7 h-7 z-10' >
                    {isMenuOpen ? <FaTimes size={30} /> : <FaBars size={30} />

                    }

                </div>
            </div>





        </nav>


    );
};

export default Navbar;