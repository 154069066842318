import React from 'react';

const About = () => {
    return (
        <div id='#about' className='w-full h-screen bg-gradient-to-b 
        from-gray-500  to-black text-white'>
            <div className='max-w-screen-lg h-full w-full mx-auto p-4 flex flex-col justify-center '>
                <div className='pb-8'>
                    <p className='text-3xl font-bold border-b-4 inline broder-gray-400'>About</p>
                </div>
                <p className='mt-20 text-xl'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam fuga error, porro facilis eaque mollitia, possimus, officia odio ullam maiores enim consequuntur nostrum! Atque asperiores mollitia quod cumque ullam. Nesciunt?</p>

                <br />
                <p className='text-xl'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id, aliquid.</p>
            </div>

        </div>
    );
};

export default About;