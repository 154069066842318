import React from "react";
import arrayDestruct from "../assets/ecommerce react.png";
import installNode from "../assets/gallery react.png";
import navbar from "../assets/restaurant1.png";
import reactParallax from "../assets/portfolio/reactParallax.jpg";
import reactSmooth from "../assets/portfolio/reactSmooth.jpg";
import reactWeather from "../assets/portfolio/reactWeather.jpg";
import { Link } from "react-router-dom";

const Portfolio = () => {
    const portfolios = [
        {
            id: 1,
            src: 'https://i.ibb.co/qrfyNYc/restaurant1.png',
            details: "Food Website Using React",
            link: "https://darling-queijadas-ae75f2.netlify.app/",
            code: "https://github.com/Hafiz0073/food-website"
        },
        {
            id: 2,
            src: 'https://i.ibb.co/c2fb4ns/ecommerce-react.png',
            details: "E-Commerce Website Using React",
            link: "https://cute-cannoli-283182.netlify.app/",
            code: "https://github.com/Hafiz0073/ecommerce-shopcart-hafiz3"

        },
        {
            id: 3,
            src: 'https://i.ibb.co/fYRsSmT/h-consultancy.png',
            details: "Consultancy Website Using React",
            link: "https://neon-stardust-7a141a.netlify.app/",
            code: "https://github.com/Hafiz0073/Visa-Consultant-Services-Client"
        },
        {
            id: 4,
            src: 'https://i.ibb.co/RpLvFsJ/superbazar1.png',
            details: "E-Commerce Website Using Wordpress",
            link: "https://superbajarbd.com/",
            code: ""
        },
        {
            id: 5,
            src: 'https://i.ibb.co/T4shQPV/shuktara1.png',
            details: "E-Commerce Website Using wordpress",
            link: "https://shuktara.shop/",
            code: ""
        },
        {
            id: 6,
            src: 'https://i.ibb.co/5MVmjzX/panenergy.png',
            details: "consultant Website Using wordpress",
            link: "https://panenergy.net/",
            code: ""
        },
        {
            id: 7,
            src: 'https://i.ibb.co/yBhB0bx/sacorporation1.png',
            details: "Contractor Website Using Bootstrap",
            link: "https://www.sacorporation.com.bd/",
            code: ""
        },
        {
            id: 8,
            src: 'https://i.ibb.co/wMgtq1w/abwings.png',
            details: "Consultant Website Using Bootstrap",
            link: "https://abwingsbd.com/",
            code: ""
        },
        {
            id: 9,
            src: 'https://i.ibb.co/72h2M5m/years1.png',
            details: "IT Website Using Bootstrap",
            link: "https://yearstech.com/",
            code: ""
        },
        {
            id: 10,
            src: 'https://i.ibb.co/8gJ2bRc/years-learning.png',
            details: "Courses Website Using React",
            link: "https://merry-shortbread-c45682.netlify.app/",
            code: ""
        },
        {
            id: 11,
            src: 'https://i.ibb.co/pf0W1nk/course-react-time-count.png',
            details: "Courses Website Using React",
            link: "https://euphonious-mousse-806344.netlify.app/",
            code: ""
        },
        {
            id: 12,
            src: 'https://i.ibb.co/KxLnmZz/gallery-react.png',
            details: "Image Gallery Manage Using React",
            link: "https://symphonious-figolla-fedd0f.netlify.app/",
            code: ""
        },
    ];

    return (
        <div
            id="#portfolio"
            className="bg-gradient-to-t from-black to-gray-800 w-full text-white  h-full "
        >
            <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
                <div className="pb-8">
                    <p className="text-4xl font-bold inline border-b-4 border-gray-500">
                        Portfolio
                    </p>
                    <p className="py-6">Check out some of my work right here</p>
                </div>

                <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
                    {portfolios.map(({ id, src, details, link }) => (
                        <div key={id} className="shadow-md shadow-gray-600 rounded-lg">
                            <img
                                src={src}
                                alt=""
                                className="rounded-md duration-200 hover:scale-105 "
                            />
                            <p className="p-5">{details}</p>
                            <div className="flex items-center justify-center">
                                <button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">
                                    <a href={link} target="_blank">Live Link</a>
                                </button>
                                <button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">
                                    <a href={link} target="_blank">Code</a>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Portfolio;